.group_invitations {
  margin-bottom: 2em;
}

.group_suggestions {
  background-color: #F0F2F5;
  padding-bottom: 1px;
}

.group_invitations header {
  display: flex;
  align-items: center;
  padding: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.25em;
  color: #999999;
}

.group_invitations .group {
  display: flex;
  flex-direction: row;
}

.group_invitations .group > .summary {
  padding: 0.5em;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.group_invitations .group .meta {
  color: #999;
  font-size: 0.75em;
}

.group_invitations .group .meta li {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0.25em;
  list-style-type: none;
}

.group_invitations .invite {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
}

.group_invitations .invite > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
